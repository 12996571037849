<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Contact</h2>
      </div>

      <template v-if="isLoading">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <div v-if="hasError" class="row">
          <div class="col-12">
            <div class="mb-4">
              <div class="alert alert-danger">
                Une erreur interne s'est produite. Réessayez dans quelques instants.
              </div>
            </div>
          </div>
        </div>

        <div class="mb-4 d-flex justify-content-between">
          <div class="row">
            <div class="col-12">
              <div class="form-inline">
                <div class="form-group mobile-wid-100">
                  <input v-model="textFilter" type="text" class="form-control mobile-wid-100" placeholder="Filtrer...">
                </div>
                <div class="ml-2 hidden-md"></div>
                <div class="form-group mt-1 mobile-wid-100">
                  <select class="custom-select mobile-wid-100" v-model="statusFilter">
                    <option value="">Tous</option>
                    <option v-for="status in statusList" :key="status.value" :value="status.value">{{ status.label }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="form-inline">
              <div class="ml-2 hidden-md"></div>
              <div class="form-group mt-1">
                <a class="btn btn-sm btn-link" @click.prevent="syncData" >
                  <fa-icon class="fa-2x text-info" :icon="['fas', 'sync']"></fa-icon>
                </a>
              </div>
              <div class="ml-2 hidden-md"></div>
              <div class="form-group mt-1">
                <a class="btn btn-sm btn-link" @click.prevent="resetFilter" >
                  <span class="fa-stack">
                    <fa-icon class="fa-stack-2x text-secondary" :icon="['fas', 'filter']"></fa-icon>
                    <fa-icon class="fa-stack fa-custom-times text-danger" :icon="['fas', 'times']"></fa-icon>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-body p-0">
            <div v-if="filteredContacts.length === 0" class="card-body text-center">
              <h4 class="header-title">Vous n'avez aucun message</h4>
            </div>
            <collection-table
              v-else
              :cols="cols"
              :list-items="filteredContacts"
              :show-actions="true"
              :default-actions="false"
              :custom-actions="getActions"
              @delete="deleteItem"
              @details="details"
              @mark="mark"
              no-top-border
              hover
              :link="(item) => `/contacts/${item.id}`"
            ></collection-table>
          </div>
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
import formatsDate from '@/mixins/formatsDate'
import { filter, orderBy } from 'lodash-es'

export default {
  mixins: [formatsDate],
  data () {
    return {
      hasError: false
    }
  },
  computed: {
    statusList () {
      return [
        {
          value: 'read',
          label: 'Lu'
        },
        {
          value: 'not_read',
          label: 'Non lu'
        }
      ]
    },
    isLoading () {
      return this.$store.state.contacts.isLoading
    },
    contacts () {
      return this.$store.state.contacts.collection
    },
    filteredContacts () {
      let items = this.contacts || []

      if (this.textFilter) {
        const searchString = this.textFilter.toLowerCase()

        items = filter(items, (item) => {
          return (
            item.name.toLowerCase().indexOf(searchString) > -1 ||
            item.email.toLowerCase().indexOf(searchString) > -1
          )
        })
      }

      if (this.statusFilter) {
        const searchStatus = this.statusFilter.toLowerCase() === 'read'

        items = filter(items, (item) => {
          return (
            item.isRead === searchStatus
          )
        })
      }

      return orderBy(items, ['createdAt'], ['desc'])
    },
    cols () {
      return [
        {
          header: 'Nom',
          property: 'name'
        },
        {
          header: 'email',
          property: 'email'
        },
        {
          header: 'Date',
          property: 'createdAt',
          headerClass: 'col-date',
          method: (item) => {
            return this.$longDateTime(item.createdAt)
          }
        },
        {
          header: 'Lu',
          property: 'isRead',
          method: (item) => {
            return item.isRead ? 'Oui' : 'Non'
          }
        }
      ]
    },
    textFilter: {
      get () {
        return this.$store.state.contacts.textFilter
      },
      set (value) {
        this.$store.commit('contacts/SET_TEXT_FILTER', value)
      }
    },
    statusFilter: {
      get () {
        return this.$store.state.contacts.statusFilter
      },
      set (value) {
        this.$store.commit('contacts/SET_STATUS_FILTER', value)
      }
    }
  },
  methods: {
    resetFilter () {
      this.textFilter = ''
      this.statusFilter = 'not_read'
    },
    async fetchData () {
      this.$store.dispatch('contacts/fetchOrRefresh')
    },
    async syncData () {
      await this.fetchData()
      this.$toasted.info('Les messages sont actualisées')
    },
    getActions (item) {
      return [
        {
          label: 'Voir',
          icon: '',
          event: 'details',
          index: 10
        },
        {
          label: item.isRead ? 'Non lu' : 'Lu',
          icon: '',
          event: 'mark',
          index: 10
        },
        {
          label: 'Supprimer',
          icon: '',
          event: 'delete',
          index: 10
        }
      ]
    },
    details (item) {
      this.$router.push({ name: 'contacts.details', params: { id: item.id } })
    },
    async deleteItem (item) {
      try {
        this.hasError = false

        await this.$modal.openConfirmModal({
          title: 'Supprimer le message',
          message: `Voulez-vous supprimer le message de: ${item.name} (${item.email})?`,
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler'
        })

        this.$store.dispatch('contacts/delete', item.id)
      } catch (error) {
        this.hasError = error !== 'ACTION_CANCELLED'
      }
    },
    mark (item) {
      this.$store.dispatch('contacts/mark', { id: item.id, isRead: !item.isRead })
    }
  },
  created () {
    this.fetchData()
  }
}
</script>

<style lang="scss" scoped>
.fa-custom-times {
  font-size: 1rem;
  position: absolute;
  top: 6px;
  left: 23px;
}
</style>
