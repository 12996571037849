<template>
  <app-layout>
    <div class="px-5 container container-center">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Détail du message</h2>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mb-4">
            <router-link class="btn btn-link btn-sm" :to="{ name: 'contacts.index' }" >
              <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
            </router-link>
          </div>
        </div>
      </div>

      <async-object
        should-fetch
        :fetch-method="fetchContact"
      >

        <div v-if="hasError" class="row">
          <div class="col-12">
            <div class="mb-4">
              <div class="alert alert-danger">
                Une erreur interne s'est produite. Réessayez dans quelques instants.
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="contact">
          <div class="col-12">
            <div class="p-3 card">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <div class="author">
                    <strong>{{ contact.name }}</strong> <small>(<a :href="`mailto: ${contact.email}`">{{ contact.email }}</a>)</small>
                  </div>
                  <div class="date">
                    <small>{{ $longDateTime(contact.createdAt) }}</small>
                  </div>
                </div>

                <div class="message">{{ contact.message }}</div>
              </div>
            </div>
          </div>
        </div>
      </async-object>
    </div>
  </app-layout>
</template>

<script>
import AsyncObject from '@/components/common/AsyncObject'
import formatsDate from '@/mixins/formatsDate'

export default {
  components: {
    AsyncObject
  },
  mixins: [formatsDate],
  data () {
    return {
      hasError: false
    }
  },
  computed: {
    contact () {
      return this.$store.getters['contacts/getDetailsById'](this.$route.params.id)
    }
  },
  methods: {
    async fetchContact () {
      await this.$store.dispatch('contacts/fetchById', this.$route.params.id)

      if (this.contact && !this.contact.isRead) {
        this.$store.dispatch('contacts/mark', { id: this.contact.id, isRead: true })
      }
    }
  }
}
</script>
